import { mapActions, mapState } from 'vuex';
import { $EventBus } from '@/main';
import CbodyTextarea from '@/components/incident/inputs/CbodyTextarea.vue';
import FormHeader from '@/components/incident/headers/FormHeader.vue';
import ValidationAlert from '@/components/global/ValidationAlert.vue';
import InputFile from '@/components/incident/bodies/InputFile/InputFile.vue';
import InvolvedForm from '@/components/incident/bodies/InvolvedForm/InvolvedForm.vue';
import textTransform from '@/helpers/textTransform.js';

export default {
	name: 'IncidenteCreate',
	data: () => ({
		steps: {
			current: 1,
			views: [1],
		},
		headers: [
			{
				text: 'Nombre',
				value: 'name',
				sortable: false,
				class: 'primary--text',
			},
			{
				text: 'Evaluación',
				value: 'evaluation',
				sortable: false,
				align: 'center',
				class: 'primary--text',
			},
			{
				text: 'Acciones',
				value: 'actions',
				sortable: false,
				align: 'end',
				class: 'primary--text',
			},
		],
		validationErrors: [],
		toolsInspection: [],
		toolDialog: false,
		evaluationToolDialog: false,
		toolEvaluation: null,
		isLoadingData: false,
		isLoadingSave: false,
		isLoadingDraft: false,
		files: null,
		evidences: [],
		evidencesSaved: [],
		involved: [],
	}),
	computed: {
		...mapState('incident', ['incidentFormat']),
		...mapState('inspection', ['inspection']),
		...mapState('register', ['register']),
		...mapState('security', ['managements', 'costCenters']),

		breadcrumbs: function () {
			return [
				{
					text: 'Reporte inmediato de incidente y/o falla de control',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentList',
					},
				},
				{
					text: 'Nuevo registro',
					disabled: true,
					href: '/',
				},
			];
		},
		isCompletedInspectons: function () {
			return this.isCompleteInspection();
		},
	},
	created() {
		this.initialize();
	},
	methods: {
		...mapActions('incident', [
			'getFormat',
			'saveFiles',
			'saveIncident',
			'cleanIncident',
		]),

		textTransform,
		initialize() {
			$EventBus.$emit('showSubNav', false);
			this.loadFormat();

			// this.find({
			// 	success: () => {
			// 		this.isLoadingData = false;
			// 	},
			// 	fail: () => {
			// 		this.isLoadingData = false;
			// 	},
			// 	id: this.$route.params?.inspectionId,
			// });
			// this.listTools();
		},
		async loadFormat() {
			this.isLoadingData = true;
			const { ok } = await this.getFormat();
			if (ok) {
				//console.log('data: ', this.$store.state.incident.format);
			}
			this.isLoadingData = false;
		},
		validateHeader() {
			let result = true;
			if (
				this.incidentFormat?.headers &&
				Array.isArray(this.incidentFormat?.headers)
			) {
				this.incidentFormat.headers.forEach((header) => {
					if (
						!header.value ||
						header.value === undefined ||
						header.value == ''
					) {
						result = false;
					}
				});
			}
			return result;
		},
		validateInvolved() {
			if (Array.isArray(this.involved)) {
				return this.involved.length > 0;
			} else {
				return false;
			}
		},
		validateBody(body = []) {
			let isCompleted = true;
			if (body?.group) {
				if (Array.isArray(body.items)) {
					body.items.forEach((item) => {
						if (
							!item?.value ||
							(Array.isArray(item?.value) && item?.value.length == 0)
						) {
							isCompleted = false;
						} else if (
							(item?.value || '').includes('Otros') &&
							!item.otherText
						) {
							isCompleted = false;
						}
					});
				}
			} else if (body?.group == null) {
				if (Array.isArray(body?.value)) {
					body?.value.forEach((description) => {
						if (!description.text) {
							isCompleted = false;
						}
					});
				} else {
					isCompleted = false;
				}
			}
			return isCompleted;
		},
		groupData(status) {
			const service_id = this.findValueInArray({
				items: this.incidentFormat?.headers,
				prop: 'key',
				val: 'service',
				propFound: 'value',
			});
			const date = this.findValueInArray({
				items: this.incidentFormat?.headers,
				prop: 'key',
				val: 'date',
				propFound: 'value',
			});
			const management_id = this.findValueInArray({
				items: this.costCenters,
				prop: 'id',
				val: service_id,
				propFound: 'management_id',
			});
			let data = {
				report: {
					date,
					service_id,
					management_id,
					status,
					company_id: parseInt(localStorage.getItem('company_id')),
				},
				fields: [],
				evidences: [],
				involved: this.involved,
			};
			data.fields = this.incidentFormat.headers.map((header) => {
				return {
					incident_field_id: header.id,
					value: (header.value || '').toString() || null,
				};
			});

			this.incidentFormat.bodies.forEach((body) => {
				if (body?.group) {
					if (Array.isArray(body.items)) {
						body.items.forEach((item) => {
							let newValue = { values: item?.value };
							if ((item?.value || '').includes('Otros')) {
								newValue.otherText = item.otherText;
							}
							data.fields.push({
								incident_field_id: item.id,
								value: JSON.stringify(newValue) || null,
							});
						});
					}
				} else {
					data.fields.push({
						incident_field_id: body.id,
						value: JSON.stringify(body.value) || null,
					});
				}
			});

			data.evidences = this.evidences.map((evidence) => {
				if (evidence?.urlPublic) {
					return {
						description: evidence?.description || null,
						file: evidence?.urlPublic,
					};
				}
			});
			return data;
		},
		async sendIncident(status = 'PENDING') {
			this.validationErrors = [];
			if (status == 'PENDING') {
				this.isLoadingSave = true;
			} else {
				this.isLoadingDraft = true;
			}
			const savedImages = await this.saveImages();
			if (savedImages) {
				const { ok, error } = await this.saveIncident(this.groupData(status));
				if (ok) {
					this.$router.push({
						name: 'IncidentList',
					});
					$EventBus.$emit(
						'showSnack',
						'success',
						'Se ha registrado satisfactoriamente.'
					);
				} else {
					if (error && error?.data?.message) {
						this.validationErrors = Array.isArray(error?.data?.message)
							? error?.data?.message
							: [];
					}
					$EventBus.$emit(
						'showSnack',
						'error',
						'Algo salio mal, no se guardo la información.'
					);
				}
				this.isLoadingSave = false;
				this.isLoadingDraft = false;
			} else {
				this.isLoadingSave = false;
				this.isLoadingDraft = false;
			}
		},
		findValueInArray({ items = [], prop = null, val = null, propFound = null }) {
			let found;
			if (Array.isArray(items) && prop && val) {
				found = items.find((item) => item[prop] == val);
				if (found && propFound) {
					found = found[propFound] ? found[propFound] : null;
				}
			}
			return found || null;
		},
		isCompleteInspection() {
			const headers = this.incidentFormat?.headers || [];
			const bodies = this.incidentFormat?.bodies || [];
			const involved = this.involved || [];
			let incomplete = false;

			headers.forEach((header) => {
				if (!header?.value) {
					incomplete = true;
				}
			});
			if (bodies.length > 0) {
				bodies.forEach((body) => {
					if (!this.validateBody(body)) {
						incomplete = true;
					}
				});
			} else {
				incomplete = true;
			}
			if (Array.isArray(involved) && involved.length == 0) {
				incomplete = true;
			}
			return !incomplete;
		},
		deleteEvidence(index) {
			this.evidences.splice(index, 1);
		},
		async saveImages() {
			let savedSuccessfully = true;
			if (Array.isArray(this.evidences) && this.evidences.length > 0) {
				let formData = new FormData();
				this.evidences.forEach((element) => {
					formData.append('files[]', element?.file, element?.file?.name);
				});

				const { ok, response, error } = await this.saveFiles(formData);
				if (ok) {
					const files = response?.saved;
					if (Array.isArray(files) && files.length > 0) {
						files.forEach((file, index) => {
							this.evidences[index].urlPublic = file.Location;
						});
					}
				} else {
					savedSuccessfully = false;
					let msmError = error?.data?.message;
					msmError = Array.isArray(msmError) ? msmError.join(' ') : msmError;
					$EventBus.$emit(
						'showSnack',
						'warning',
						(
							msmError || 'Algo salio mal, no se guardo la información.'
						).toString()
					);
				}
			}
			return savedSuccessfully;
		},
	},
	destroyed() {
		this.cleanIncident();
	},
	components: { FormHeader, ValidationAlert, CbodyTextarea, InputFile, InvolvedForm },
};
